import type { FC } from "react";
import React from "react";

const PhoneIcon: FC<{ width?: number; height?: number }> = ({
  width = 16,
  height = 16
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2188 17.25C16.509 17.25 16.7535 17.1507 16.9521 16.9521C17.1507 16.7535 17.25 16.509 17.25 16.2188V13.125C17.25 12.8806 17.1736 12.6667 17.0208 12.4833C16.8681 12.3 16.6771 12.1854 16.4479 12.1396L13.7208 11.5437C13.5222 11.4979 13.3236 11.5094 13.125 11.5781C12.9264 11.6469 12.7507 11.7653 12.5979 11.9333L10.4208 14.1792C9.07639 13.4458 7.87326 12.5521 6.81146 11.4979C5.74965 10.4438 4.79097 9.20625 3.93542 7.78542L6.22708 5.47083C6.34931 5.34861 6.42951 5.19965 6.46771 5.02396C6.5059 4.84826 6.50972 4.65347 6.47917 4.43958L5.86042 1.55208C5.81458 1.30764 5.71146 1.11285 5.55104 0.967708C5.39062 0.822569 5.20347 0.75 4.98958 0.75H1.78125C1.49097 0.75 1.24653 0.849306 1.04792 1.04792C0.849306 1.24653 0.75 1.49097 0.75 1.78125C0.75 3.64514 1.20833 5.49757 2.125 7.33854C3.04167 9.17951 4.23333 10.8333 5.7 12.3C7.16667 13.7667 8.82049 14.9583 10.6615 15.875C12.5024 16.7917 14.3549 17.25 16.2188 17.25Z"
        fill="black"
      />
    </svg>
  );
};

export default PhoneIcon;
