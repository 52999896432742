import React, { useEffect, useMemo } from "react";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import { TranscarentSsoControllerService } from "@9amhealth/openapi";
import Translate from "../Translate/Translate";
import styled from "@emotion/styled";
import { authenticationState } from "src/state/state";
import envVariables from "src/lib/envVariables";
import parseJwt from "src/lib/parseJwt";

const NiceError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    padding: 1rem;
    font-weight: 400;
  }

  em {
    font-style: italic;
  }

  pre {
    font-size: 1rem;
    padding: 1rem;
    background-color: rgb(240, 240, 240);
    border-radius: 0.5rem;
    max-width: 90vw;
    text-align: left;
  }
`;

const HandleOneTimeToken: FC = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = React.useState<string | null>(null);
  const [response, setResponse] = React.useState<unknown | null>(null);
  const token = searchParams.get("token");
  const currentAuthToken = authenticationState.accessToken;
  const isProd = envVariables.APP_ENV === "production";

  const currentTokenDetails = useMemo(() => {
    if (currentAuthToken) {
      try {
        const decoded = parseJwt(currentAuthToken);
        return {
          token,
          userId: decoded.sub,
          expired: decoded.expired
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        setError("Invalid Auth Token");
        return null;
      }
    }
    return null;
  }, [currentAuthToken]);

  const exchangeTokenDetails = useMemo(() => {
    if (token) {
      try {
        const decoded = parseJwt(token);
        return {
          token,
          userId: decoded.sub
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        setError("Invalid Exchange Token");
        return null;
      }
    }
    setError("Missing Exchange Token");
    return null;
  }, [token]);

  const handleAuthSuccess = () => {
    window.location.replace("/app/home?from=tc-ott");
  };

  useEffect(() => {
    if (exchangeTokenDetails) {
      if (
        currentTokenDetails &&
        !currentTokenDetails.expired &&
        currentTokenDetails.userId === exchangeTokenDetails.userId
      ) {
        handleAuthSuccess();
        return;
      }

      void TranscarentSsoControllerService.exchangeToken({
        token: exchangeTokenDetails.token
      })
        .then((res) => {
          void authenticationState
            .setCredentials({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken
            })
            .then(() => {
              handleAuthSuccess();
            })
            .catch(() => {
              setError("Could not set credentials");
            });
        })
        .catch((err: unknown) => {
          setResponse(err);
          if (err instanceof Error) {
            setError(err.message);
          }
        });
    }
  }, [exchangeTokenDetails, currentTokenDetails]);

  if (error) {
    return (
      <NiceError>
        <h1>
          <Translate msg="auth.ott.error" />
        </h1>
        {!isProd && (
          <>
            <em>error:</em>
            <pre>{error}</pre>
            <em>response:</em>
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </>
        )}
      </NiceError>
    );
  }

  return <Loader active fixed absolute fullPage bg="partner" />;
};

export default HandleOneTimeToken;
