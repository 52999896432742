import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FunnelKey from "src/constants/funnelKey";
import { APP_BREAKPOINT } from "src/constants/layout";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import sanityQuery from "src/lib/sanityQuery";
import SanityCubit from "src/state/SanityCubit/SanityCubit";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import { tracker, useBloc } from "src/state/state";
import Logo from "src/ui/assets/icons/Logo";
import { ContactInfo } from "src/ui/components/ContactInfo/ContactInfo";
import SanityBlockContent, {
  type SanityContentBlock
} from "src/ui/components/SanityBlockContent/SanityBlockContent";
import RegisterForm from "../../RegisterForm/RegisterForm";

const Page = styled.main`
  min-height: 100vh;
  min-height: 100svh;
  overflow: scroll;
  height: 100vh;
  height: 100svh;
  padding-bottom: 1.5rem;
  width: 100%;

  @media screen and (min-width: 955px) {
    padding-bottom: 3rem;
  }
`;

const Content = styled.div`
  width: calc(1100px + 4rem);
  max-width: 100%;
  margin: 0 auto;
  padding: 0 min(2rem, 4vw);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media screen and (min-width: 955px) {
    display: flex;
    padding-top: 3rem;
    flex-direction: initial;
  }
`;

const TextContent = styled.div`
  flex: 1;
  text-align: center;

  p {
    font-weight: 700;
    font-size: min(20px, 4.5vw);
    line-height: 1.1;
    letter-spacing: -0.01em;
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    max-width: calc(400px + 2rem);
    padding-right: 2rem;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: #e3f6f3;
  width: 100%;
  min-height: 150px;
  border-radius: 6px;
  padding: 1.5rem min(1rem, 3vw) 1rem;
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.11);

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    padding: 3.5rem min(1rem, 3vw) 1rem;
  }
`;

const Header = styled.div`
  padding: min(2rem, 3vw) min(5rem, 5vw);
  margin: 0 auto 3rem;
  background: var(--color-error);
  display: flex;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1380px;
  flex: 1;
`;

const SignupWrapper = styled.div`
  max-width: 580px;
  width: 100%;
  margin: 0 auto 30px;
  display: flex;

  @media screen and (min-width: 955px) {
    margin: 0 auto;
  }
`;

interface PageContent {
  title: string;
  slug: { current: string };
  language: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: Record<string, any>[];
}

const RegisterPage: FC = () => {
  const navigate = useNavigate();
  const { coupon } = useParams<{ coupon: string | undefined }>();

  const [, { fetchFirst, firstElement }] = useBloc(SanityCubit, {
    create: () => new SanityCubit<PageContent>()
  });

  useEffect(() => {
    tracker.track(TrackEvent.PromoRegisterPage, {
      type: TrackType.open
    });
    void fetchFirst(sanityQuery.getAppContentBySlug("test"));
  }, []);

  const handleSuccess = (): void => {
    if (coupon) {
      navigate(
        createAppPath(
          Path.joinCoupon.replace(":coupon", coupon) as Path,
          "join"
        )
      );
    } else {
      navigate(Path.app);
    }
  };

  return (
    <Page>
      <Header>
        <HeaderContainer>
          <Logo />
          <ContactInfo />
        </HeaderContainer>
      </Header>
      <Content>
        <TextContent>
          <SanityBlockContent
            blocks={firstElement?.content as unknown as SanityContentBlock}
          />
        </TextContent>
        <SignupWrapper>
          <Container>
            <RegisterForm
              buttonLabel={"register_alternative_button_label"}
              title={"register_alternative_title"}
              description={"register_alternative_description"}
              limitWidth={false}
              loginLink={Path.authLogin}
              noFrame
              onSuccess={handleSuccess}
              scopeKey={FunnelKey.promo}
            />
          </Container>
        </SignupWrapper>
      </Content>
    </Page>
  );
};

export default RegisterPage;
