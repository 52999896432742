import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useCallback } from "react";
import { signupHeaderBreakpoint } from "src/constants/layout";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker } from "src/state/state";
import PhoneIcon from "src/ui/assets/icons/PhoneIcon";
import Translate from "src/ui/components/Translate/Translate";

const Contact = styled.div<{ hideMobile?: boolean }>`
  font-size: 17px;
  display: ${({ hideMobile }): string => (hideMobile ? "none" : "flex")};
  margin: 0 24px 0;

  strong {
    display: none;
  }

  @media screen and (min-width: ${signupHeaderBreakpoint}px) {
    flex-direction: column;
    display: flex;
    margin: 0;

    strong {
      display: block;
    }
  }
`;

const PhoneIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;

  @media screen and (min-width: ${signupHeaderBreakpoint}px) {
    display: none;
  }
`;

const PhoneLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: black;

  span:first-of-type {
    margin-right: 5px;
    display: none;
  }

  span {
    text-decoration: underline;
  }

  @media screen and (min-width: ${signupHeaderBreakpoint}px) {
    span:first-of-type {
      display: flex;
    }

    span {
      text-decoration: none;
    }
  }
`;

export const ContactInfo: FC<{ hideMobile?: boolean }> = ({
  hideMobile = true
}) => {
  const handleClick = useCallback(() => {
    tracker.track("Phone Link Clicked" as TrackEvent, {});
  }, []);

  return (
    <Contact hideMobile={hideMobile}>
      <strong>
        <Translate msg="help.questions" />
      </strong>{" "}
      <PhoneLink onClick={handleClick} href="tel:+12029329958">
        <PhoneIconWrapper>
          <PhoneIcon />
        </PhoneIconWrapper>
        <span>
          <Translate msg="help.phoneSupport.short" />
        </span>
        <span>(202) 932-9958</span>
      </PhoneLink>
    </Contact>
  );
};
