import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "src/constants/icons";

import SignupCubit from "src/state/SignupCubit/SignupCubit";
import { useBloc } from "src/state/state";
import { NineNavigation } from "@9amhealth/wcl/generated/react";
import Translate from "../Translate/Translate";

const SignupHeader: FC = () => {
  const [signup, { headerSteps, hideSignupHeaderForPath }] =
    useBloc(SignupCubit);
  const navigate = useNavigate();

  const progressPercent = useMemo(() => {
    const { currentStep } = signup;
    const totalSteps = headerSteps.length;

    return (currentStep / totalSteps) * 100;
  }, [signup]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, []);

  const { pathname } = useLocation();

  return hideSignupHeaderForPath(pathname) ? (
    <></>
  ) : (
    <NineNavigation
      app
      variant="stepper"
      style={{ background: `var(--color-cream)` }}
    >
      {!signup.hideProgressBar && (
        <nine-progress-bar
          slot="progress"
          progress={`${progressPercent}%`}
        ></nine-progress-bar>
      )}

      <nine-overlay slot="help" variant="toast">
        <div slot="trigger" className="strong">
          <Translate msg="help.question" />
        </div>
        <div slot="content">
          <h5 className="strong m0">
            <Translate msg="help.questions" />
          </h5>
          <p className="lauf-text">
            <Translate msg="help.phoneSupport" />{" "}
            <a href="tel:2029329958">(202) 932-9958</a>
            <br />
            <Translate msg="help.emailSupport" />{" "}
            <a href="mailto:support@join9am.com">support@join9am.com</a>
          </p>
        </div>
      </nine-overlay>

      {signup.showBackButton && (
        <button slot="back" className="custom-button" onClick={goBack}>
          <ArrowLeftIcon />
          <span
            className="lh-neutral hide-mobile"
            style={{ marginLeft: `calc(var(--space-sm) * 0.625)` }}
          >
            <Translate msg="back" />
          </span>
        </button>
      )}
    </NineNavigation>
  );
};

export default SignupHeader;
